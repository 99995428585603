import { routes } from 'consts/routes';
import { useNavigate } from 'react-router-dom';
import { urlHelper } from 'utils/helpers';
import { useGlobalState } from './useGlobalState';

export enum ROUTE_NAMES {
  COUNTRY_SELECTION = 'COUNTRY_SELECTION',
  ERROR_403 = 'ERROR_403',
  HOME_TOOLS = 'HOME_TOOLS',
  ACCOUNT_TOOLS = 'ACCOUNT_TOOLS',
  ACCOUNT_SELECTION_HOME = 'ACCOUNT_SELECTION_HOME',
  ACCOUNT_SELECTION_STEP_ONE = 'ACCOUNT_SELECTION_STEP_ONE',
  ACCOUNT_SELECTION_STEP_TWO = 'ACCOUNT_SELECTION_STEP_TWO',
  CUSTOMER_SELECTION_STEP_ONE = 'CUSTOMER_SELECTION_STEP_ONE',
  CUSTOMER_SELECTION_STEP_TWO = 'CUSTOMER_SELECTION_STEP_TWO',
  ACCOUNT_INVOICES_LIST = 'ACCOUNT_INVOICES_LIST',
  ACCOUNT_INVOICE_DETAILS = 'ACCOUNT_INVOICE_DETAILS',
  ACCOUNT_ORDERS_LIST = 'ACCOUNT_ORDERS_LIST',
  ACCOUNT_ORDER_DETAILS = 'ACCOUNT_ORDER_DETAILS',
  ACCOUNT_ALGO_SELLING = 'ACCOUNT_ALGO_SELLING',
  ACCOUNT_CUSTOMERS_LIST = 'ACCOUNT_CUSTOMERS_LIST',
  ACCOUNT_CUSTOMER_DETAILS = 'ACCOUNT_CUSTOMER_DETAILS',
  ACCOUNT_TRUCK_REVIEW = 'ACCOUNT_TRUCK_REVIEW',
  ACCOUNT_TRUCK_SIMULATION = 'ACCOUNT_TRUCK_SIMULATION',
  ACCOUNT_PRODUCTS_LIST = 'ACCOUNT_PRODUCTS_LIST',
  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',
  ACCOUNT_REWARDS = 'ACCOUNT_REWARDS',
  ACCOUNT_REWARD_CHALLENGE_DETAILS = 'ACCOUNT_REWARD_CHALLENGE_DETAILS',
  ACCOUNT_DELIVERY_WINDOWS = 'ACCOUNT_DELIVERY_WINDOWS',
  STORES_LIST = 'STORES_LIST',
  STORE_TOOLS = 'STORE_TOOLS',
  STORE_CATEGORIES_LIST = 'STORE_CATEGORIES_LIST',
  STORE_CATEGORY_DETAILS = 'STORE_CATEGORY_DETAILS',
  STORE_ITEMS_LIST = 'STORE_ITEMS_LIST',
  STORE_VENDORS_LIST = 'STORE_VENDORS_LIST',
  VENDORS_LIST = 'VENDORS_LIST',
  VENDOR_TOOLS = 'VENDOR_TOOLS',
  VENDOR_DETAILS = 'VENDOR_DETAILS',
  VENDOR_CATEGORIES_LIST = 'VENDOR_CATEGORIES_LIST',
  VENDOR_CATEGORY_DETAILS = 'VENDOR_CATEGORY_DETAILS',
  BEES_VENDO_SELECTION = 'BEES_VENDO_SELECTION',
  BEES_VENDO_TRANSACTIONS = 'BEES_VENDO_TRANSACTIONS',
  BEES_FORCE_SEARCH = 'BEES_FORCE_SEARCH',
  BEES_FORCE_DETAILS_BDR = 'BEES_FORCE_DETAILS_BDR',
}

interface INavigateParams {
  path: string;
  params?: { [key: string]: string | undefined };
  state?: unknown;
}

interface IUseApiaryNavigationResponse {
  navigate: (params: INavigateParams) => void;
  getUrl: (params: INavigateParams) => string;
}

interface StringIndex {
  [key: string]: string;
}

export const useApiaryNavigation = (): IUseApiaryNavigationResponse => {
  const navigation = useNavigate();
  const {
    system: { selectedCountry },
  } = useGlobalState();

  const router = routes as StringIndex;
  const getUrl = ({ path, params }: INavigateParams) => {
    const keys = Object.keys(routes);
    const keyValue = keys.find((key) => key === String(path));
    if (!keyValue) return '';
    const countryCode = selectedCountry?.code ?? params?.countryCode;
    if (!countryCode) return routes.COUNTRY_SELECTION;

    return urlHelper.urlBuilder(router[keyValue], {
      countryCode,
      ...params,
    });
  };

  const navigate = ({ path, params = {}, state = {} }: INavigateParams) => {
    const paramKeys = Object.keys(params);
    paramKeys.forEach((key) => {
      const value = params[key];
      if (value) {
        params[key] = encodeURIComponent(value);
      }
    });

    const url = getUrl({ path, params });
    navigation(url, { state });
  };

  return {
    navigate,
    getUrl,
  };
};
