import { IAccountBox } from 'interfaces/IAccountBox';
import { IBeesForceSearch } from 'interfaces/IBeesForce';
import { IBeesVendo } from 'interfaces/IBeesVendo';
import { ICustomer } from 'interfaces/ICustomer';
import { IStoreBox } from 'interfaces/IStoreBox';
import { IVendorBox } from 'interfaces/IVendorBox';
import {
  SET_ACCOUNT_CARD_BOX,
  SET_BEES_FORCE_CARD_BOX,
  SET_BEES_VENDO_CARD_BOX,
  SET_CUSTOMER_CARD_BOX,
  SET_STORE_CARD_BOX,
  SET_VENDOR_CARD_BOX
} from 'redux/actionTypes';

import { ICardBoxAction } from 'redux/interfaces';

export const setBeesForceSearch = (beesForce?: IBeesForceSearch): ICardBoxAction => ({
  type: SET_BEES_FORCE_CARD_BOX,
  payload: {
    beesForce,
  },
});

export const setAccountCardBox = (account?: IAccountBox): ICardBoxAction => ({
  type: SET_ACCOUNT_CARD_BOX,
  payload: {
    account,
  },
});
export const setVendorCardBox = (vendor?: IVendorBox): ICardBoxAction => ({
  type: SET_VENDOR_CARD_BOX,
  payload: {
    vendor,
  },
});
export const setBeesVendoCardBox = (beesVendo?: IBeesVendo): ICardBoxAction => ({
  type: SET_BEES_VENDO_CARD_BOX,
  payload: {
    beesVendo,
  },
});
export const setStoreCardBox = (store?: IStoreBox): ICardBoxAction => ({
  type: SET_STORE_CARD_BOX,
  payload: {
    store,
  },
});
export const setCustomerCardBox = (customer?: ICustomer): ICardBoxAction => ({
  type: SET_CUSTOMER_CARD_BOX,
  payload: {
    customer,
  },
});
